export const links = [
	{
		id: "#intro",
		name: "Home",
	},
	{
		id: "#featured",
		name: "Featured",
	},
	{
		id: "#skills",
		name: "Skills",
	},
	{
		id: "#portfolio",
		name: "Portfolio",
	},
	// {
	// 	id: "#works",
	// 	name: "Works",
	// },
	// {
	// 	id: "#testimonials",
	// 	name: "Testimonials",
	// },

	{
		id: "#contact",
		name: "Contact",
	},
];
